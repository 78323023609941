<template>
  <div>
    <div class="d-flex flex-row">
      <button
        :disabled="IsLoading"
        type="button"
        class="btn btn-white btn-sm text-nowrap"
        @click="fetchAccounts"
      >Reload</button>
      <button
        :disabled="IsLoading"
        type="button"
        class="btn btn-primary btn-sm ml-2 text-nowrap"
        @click="createAccount"
      >Create new account</button>

      <div>
        <b-form-select
          v-model="sortBy"
          :options="sortOptions"
          size="sm"
          class="sort-select select-amz d-inline-block ml-2"
          @change="filterAccounts"
        ></b-form-select>
      </div>
    </div>

    <b-form-input
        v-model="searchText"
        class="search-text mt-3"
        type="text"
        debounce="150"
        placeholder="Search ID, Name, Login"
        autofocus
        autocomplete="off"
        @update="filterAccounts"
      ></b-form-input>

    <table class="table table-google table-accounts mt-2">
      <thead>
        <tr>
          <th class="text-right">ID</th>
          <th>Name</th>
          <th>Login</th>
          <th>Password</th>
          <th>Admin</th>
          <th>Mandants</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="account in filteredAccounts" :key="account.id">
          <!-- id -->
          <td class="row-fit text-right">{{ account.id }}</td>
          <!-- display_name -->
          <td>
            <b-form-input
              v-if="account.editMode"
              v-model="account.display_name"
              size="sm"
              placeholder="Display name"
              @keyup.enter="saveAccountChanges(account)"
              :disabled="account.loading"
            ></b-form-input>
            <span v-else>
              {{ account.display_name }}
            </span>
          </td>
          <!-- login -->
          <td>
            <b-form-input
              v-if="account.editMode"
              v-model="account.login"
              size="sm"
              placeholder="Login"
              @keyup.enter="saveAccountChanges(account)"
              :disabled="account.loading"
            ></b-form-input>
            <span v-else :class="{ 'text-unblock font-weight-bold': account.blocked }">
              {{ account.login }}
            </span>
          </td>
          <!-- password -->
          <td>
            <div v-if="account.editMode">
               <b-input-group>
                <b-form-input
                  v-model="account.password"
                  size="sm"
                  placeholder="Password"
                  @keyup.enter="saveAccountChanges(account)"
                  :disabled="account.loading"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    variant="white"
                    @click="generateNewPassword(account)"
                    title="Generate new password"
                    :disabled="account.loading"
                  >
                    Generate new password
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <span v-else class="text-monospace">
              <template v-if="account.showPassword">
                {{ account.password }}
              </template>
              <template v-else>
                {{ account.password | mask }}
              </template>
            </span>
          </td>
          <!-- admin -->
          <td class="row-fit">
            <div v-if="account.editMode">
               <b-form-checkbox
                  v-model="account.admin"
                  :value="true"
                  :unchecked-value="false"
                ></b-form-checkbox>
            </div>
            <div v-else>
              <i v-if="account.admin" class="fas fa-user-shield" title="Admin"></i>
            </div>
          </td>
          <!-- mandants -->
          <td class="row-fit text-nowrap">
            {{ generateMandantsText(account.mandants) }}
          </td>
          <!-- action -->
          <td class="text-nowrap row-fit">
            <div v-if="account.editMode" class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary btn-sm mr-1"
                :disabled="account.loading"
                @click="saveAccountChanges(account)"
                title="Save changes"
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-white btn-sm"
                :disabled="account.loading"
                @click="cancelAccountChanges(account)"
                title="Cancel changes"
              >
                Cancel
              </button>
            </div>
            <div v-else class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-white btn-sm mr-1"
                @click="account.showPassword = !account.showPassword"
                :title="account.showPassword ? 'Hide password' : 'Show password'"
              >
                <span v-if="account.showPassword">Hide password</span>
                <span v-else>Show password</span>
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm mr-1"
                :disabled="account.loading"
                @click="account.editMode = true"
                title="Edit account"
              >
                Edit
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm mr-1"
                :disabled="account.loading"
                @click="deleteAccount(account)"
                title="Delete account"
              >
                Delete
              </button>
              <button
                type="button"
                class="btn btn-white btn-sm font-weight-bold"
                :class="[account.blocked ? 'text-block' : ' text-unblock']"
                :disabled="account.loading"
                style="width: 70px;"
                @click="blockAccount(account)"
                :title="`${account.blocked ? 'Unblock' : 'Block'} account`"
              >
                {{ account.blocked ? 'Unblock' : 'Block' }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const _ = require('lodash');

export default {
  name: 'AdminAccounts',
  components: {},
  computed: {
    IsLoading() {
      return this.loadingCount > 0 || this.accounts.some((account) => account.loading);
    },
  },
  data() {
    return {
      loadingCount: 0,
      accounts: [],
      filteredAccounts: [],
      searchText: '',
      sortBy: 'display_name',
      sortOptions: [
        { value: 'id', text: 'Sort by: ID' },
        { value: 'login', text: 'Sort by: Login' },
        { value: 'display_name', text: 'Sort by: Name' },
      ],
    };
  },
  methods: {
    setupAccountMeta(account) {
      account.showPassword = false;
      account.editMode = false;
      account.loading = false;
    },
    fetchAccounts() {
      if (this.IsLoading) return;
      this.loadingCount += 1;
      this.$http
        .get('/accounts')
        .then((res) => {
          res.body.accounts.forEach((account) => {
            this.setupAccountMeta(account);
          });
          this.accounts = res.body.accounts;
          this.filterAccounts();
        })
        .catch((err) => {
          alert(`Failed to fetch accounts: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    createAccount() {
      if (this.IsLoading) return;
      const login = prompt('Enter login name (e-mail)');
      if (login === null || login === '') return;
      this.loadingCount += 1;
      this.$http
        .post('/accounts')
        .send({ login })
        .then((res) => {
          this.setupAccountMeta(res.body.account);
          this.accounts.push(res.body.account);
          this.filterAccounts();
        })
        .catch((err) => {
          alert(`Failed to create new account: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    deleteAccount(account) {
      if (account.loading) return;
      const confirmed = confirm(`Do you really wish to delete ${account.login}?`);
      if (!confirmed) return;
      account.loading = true;
      this.$http
        .delete(`/accounts/${account.id}`)
        .then(() => {
          this.accounts = this.accounts.filter((acc) => acc.id !== account.id);
          this.filterAccounts();
        })
        .catch((err) => {
          alert(`Failed to delete account: ${err.response.text}`);
        })
        .finally(() => {
          account.loading = false;
        });
    },
    blockAccount(account) {
      if (account.loading) return;

      account.loading = true;
      const accountChanges = {
        blocked: !account.blocked,
      };
      this.$http
        .put(`/accounts/${account.id}`)
        .send({ accountChanges })
        .then((res) => {
          account.blocked = res.body.account.blocked;
        })
        .catch((err) => {
          alert(`Failed to save changes: ${err.response.text}`);
        })
        .finally(() => {
          account.loading = false;
        });
    },
    saveAccountChanges(account) {
      if (account.loading) return;

      account.loading = true;
      const accountChanges = {
        login: account.login,
        password: account.password,
        display_name: account.display_name,
        admin: account.admin,
      };
      this.$http
        .put(`/accounts/${account.id}`)
        .send({ accountChanges })
        .then((res) => {
          this.setupAccountMeta(res.body.account);
          // just to verify that changes were made
          Object.keys(account).forEach((key) => {
            account[key] = res.body.account[key];
          });
          account.editMode = false;
          this.filterAccounts();
        })
        .catch((err) => {
          alert(`Failed to save changes: ${err.response.text}`);
        })
        .finally(() => {
          account.loading = false;
        });
    },
    cancelAccountChanges(account) {
      if (account.loading) return;

      account.loading = true;
      this.$http
        .get(`/accounts/${account.id}`)
        .then((res) => {
          this.setupAccountMeta(res.body.account);
          Object.keys(account).forEach((key) => {
            account[key] = res.body.account[key];
          });
          this.filterAccounts();
        })
        .catch((err) => {
          alert(`Failed to show live values: ${err.response.text}`);
        })
        .finally(() => {
          account.editMode = false;
          account.loading = false;
        });
    },
    generateNewPassword(account) {
      // generates 6 characters long password using 0-9, a-z
      const newPassword = (
        Math.floor(Math.random() * (2147483647 - 1000000000 + 1)) + 1000000000
      ).toString(36);
      account.password = newPassword;
    },
    generateMandantsText(mandants) {
      if (mandants.length === 0) return '';
      let mandantsText = '';
      // add first 2 mandants
      const mandantsToShow = [];
      for (let i = 0; i < 2 && i < mandants.length; i++) {
        mandantsToShow.push(mandants[i]);
      }
      mandantsText = mandantsToShow.join(', ');

      // show the rest as additional mandants
      const additionalMandants = Math.max(0, mandants.length - 2);
      if (additionalMandants > 0) mandantsText += `, ${additionalMandants.toString()} more`;

      return mandantsText;
    },
    filterAccounts() {
      this.filteredAccounts = this.accounts.filter((account) => (
        account.editMode
          || account.id === this.searchText
          || account.login.toLowerCase().includes(this.searchText.toLowerCase())
          || account.display_name.toLowerCase().includes(this.searchText.toLowerCase())
      ));
      this.filteredAccounts = _.sortBy(this.filteredAccounts, this.sortBy);
    },
  },
  created() {
    this.fetchAccounts();
  },
};
</script>

<style lang="scss" scoped>
.search-text,
.table-accounts {
  max-width: 1200px;
}
.sort-select {
  width: 150px;
}
.text-block {
  color: #008000;
}
.text-unblock {
  color: #db0000;
}
</style>
